import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight, Sparkles, History, FileText } from "lucide-react";
import { Link } from "@remix-run/react";

export default function ConversionHero({
    content,
}: {
    content: {
        job_title: string;
        job_positions: string[];
    };
}) {
    const [displayedText, setDisplayedText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);

    useEffect(() => {
        const text = content.job_positions[loopNum % content.job_positions.length];

        const handleTyping = () => {
            if (isDeleting) {
                setDisplayedText(text.substring(0, displayedText.length - 1));
            } else {
                setDisplayedText(text.substring(0, displayedText.length + 1));
            }

            setTypingSpeed(isDeleting ? 75 : 150);

            if (!isDeleting && displayedText === text) {
                setIsDeleting(true);
                setTypingSpeed(2000); // Pause longer at the end
            } else if (isDeleting && displayedText === "") {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setTypingSpeed(500);
            }
        };

        const timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
    }, [displayedText, isDeleting, loopNum, typingSpeed, content]);

    return (
        <section className="relative min-h-screen flex items-center justify-center overflow-hidden py-20">
            <div className="absolute inset-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700" />
            <div className="absolute inset-0 bg-[url('/circuit-board.svg')] opacity-10" />
            <div className="relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center">
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl"
                >
                    {content.job_title} CV Builder
                    <br />
                    <span className="relative inline-block mt-2">
                        <span className="text-yellow-300">{displayedText}</span>
                        <span className="absolute -right-4 top-0 h-full w-1 bg-yellow-300 animate-blink" />
                    </span>
                </motion.h1>

                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="mt-6 max-w-2xl mx-auto text-xl text-gray-200"
                >
                    Looking to land your next {content.job_title} role? TailoredCV.ai helps you
                    craft a tailored, ATS-optimized resume designed to match job descriptions
                    perfectly.
                </motion.p>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4"
                >
                    <div className="flex max-w-md w-full mx-auto">
                        {/* <Input
                            type="email"
                            placeholder="your@email.com"
                            className="rounded-r-none focus-visible:ring-0 focus-visible:ring-offset-0 bg-white/10 text-white placeholder:text-white border-r-0 h-12"
                        /> */}
                        <Button
                            asChild
                            className="bg-white text-purple-700 hover:bg-gray-100 h-12 px-8 mx-auto"
                        >
                            <Link to={"/auth/login"} className="flex items-center gap-2">
                                Get Started
                                <ArrowRight className="ml-2 h-5 w-5" />
                            </Link>
                        </Button>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3"
                >
                    {[
                        {
                            icon: Sparkles,
                            text: "Smart skill matching and keyword optimization for every application",
                        },
                        {
                            icon: History,
                            text: "Track and manage multiple versions of your resume",
                        },
                        {
                            icon: FileText,
                            text: "AI-generated cover letters tailored to each application",
                        },
                    ].map((feature, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <feature.icon className="h-12 w-12 text-yellow-300" />
                            <p className="mt-4 text-lg font-medium text-white">{feature.text}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}
