import { json, type MetaFunction, type LoaderFunctionArgs } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import type { ReactNode } from "react";
import { z } from "zod";
import ConversionFeatures from "~/components/website/conversion/features";
import ConversionHero from "~/components/website/conversion/hero";
import ConversionHowItWorks from "~/components/website/conversion/how-it-works";
import ConversionTestimonials from "~/components/website/conversion/testimonials";
import { FAQComponent } from "~/components/website/faq";
import PricingSection from "~/components/website/pricing";

export const meta: MetaFunction = ({ params }) => {
    return [
        { title: "TailoredCV.ai | Your CV optimization tool" },
        {
            name: "description",
            content:
                "TailoredCV.ai helps you create a standout resume by tailoring your CV to match job descriptions using advanced AI features.",
        },
        {
            name: "keywords",
            content: "TailoredCV, TailoredCV.ai, CV, Job Search, AI, Optimization, Tailored Resume",
        },
        {
            property: "og:url",
            content: `https://tailoredcv.ai/${params.slug}`,
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            property: "og:description",
            content:
                "TailoredCV.ai helps you create a standout resume by tailoring your CV to match job descriptions using advanced AI features.",
        },
        {
            property: "og:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            property: "og:title",
            content: "TailoredCV.ai | Your CV optimization tool",
        },
        {
            property: "og:site_name",
            content: "TailoredCV.ai",
        },
        {
            name: "twitter:card",
            content: "summary_large_image",
        },
        {
            property: "twitter:domain",
            content: "tailoredcv.ai",
        },
        {
            property: "twitter:url",
            content: `https://tailoredcv.ai/${params.slug}`,
        },
        {
            name: "twitter:title",
            content: "TailoredCV.ai | Your CV optimization tool",
        },
        {
            name: "twitter:description",
            content:
                "TailoredCV.ai helps you create a standout resume by tailoring your CV to match job descriptions using advanced AI features.",
        },
        {
            name: "twitter:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            name: "image",
            content: "https://tailoredcv.ai/og-image.png",
        },
    ];
};

const AlternatingBackground = ({ children, index }: { children: ReactNode; index: number }) => (
    <div
        className={`w-full py-12 ${index % 2 === 0 ? "bg-white" : "bg-gradient-to-br from-pink-600/5 via-purple-700/5 to-blue-700/5"}`}
    >
        {children}
    </div>
);

const schema = z.object({
    slug: z.string(),
    jobTitle: z.string(),
    jobPositions: z.preprocess((val) => {
        if (typeof val === "string") {
            return JSON.parse(val);
        }
        return val;
    }, z.array(z.string())),
    industry: z.string(),
    jobTitlePlural: z.string(),
    resumeData: z.preprocess(
        (val) => {
            if (typeof val === "string") {
                return JSON.parse(val);
            }
            return val;
        },
        z.object({
            name: z.string(),
            email: z.string(),
            phone: z.string(),
            avatar: z.string(),
            skills: z.preprocess(
                (val) => {
                    if (typeof val === "string") {
                        return JSON.parse(val);
                    }
                    return val;
                },
                z.array(
                    z.object({
                        name: z.string(),
                        level: z.number(),
                    }),
                ),
            ),
            experience: z.preprocess(
                (val) => {
                    if (typeof val === "string") {
                        return JSON.parse(val);
                    }
                    return val;
                },
                z.array(
                    z.object({
                        title: z.string(),
                        company: z.string(),
                        period: z.string(),
                        description: z.string(),
                    }),
                ),
            ),
        }),
    ),
    jobTitles: z.preprocess(
        (val) => {
            if (typeof val === "string") {
                return JSON.parse(val);
            }
            return val;
        },
        z.array(
            z.object({
                title: z.string(),
                relevantSkills: z.preprocess((val) => {
                    if (typeof val === "string") {
                        return JSON.parse(val);
                    }
                    return val;
                }, z.array(z.string())),
                relevantExperience: z.preprocess((val) => {
                    if (typeof val === "string") {
                        return JSON.parse(val);
                    }
                    return val;
                }, z.array(z.string())),
            }),
        ),
    ),
    topSkills: z.preprocess((val) => {
        if (typeof val === "string") {
            return JSON.parse(val);
        }
        return val;
    }, z.array(z.string())),
    testimonials: z.preprocess(
        (val) => {
            if (typeof val === "string") {
                return JSON.parse(val);
            }
            return val;
        },
        z.array(
            z.object({
                quote: z.string(),
                name: z.string(),
                title: z.string(),
                initials: z.string(),
            }),
        ),
    ),
});

export async function loader({ request, context, params }: LoaderFunctionArgs) {
    const { slug } = params;

    const db = context.cloudflare.env.PSEO_DB;

    const query = db
        .prepare(
            `SELECT 
                *
             FROM 'pseo-job-titles' WHERE slug = ?`,
        )
        .bind(slug);

    const data = await query.first();

    if (!data) {
        throw new Response(null, { status: 404, statusText: "Not Found" });
    }

    const parsedData = schema.parse(data);

    return json({ ...parsedData, slug });
}

export default function Conversion() {
    const data = useLoaderData<typeof loader>();

    const sections = [
        <ConversionFeatures
            key={1}
            industry={data.industry}
            industryTitles={data.jobTitlePlural}
            jobTitle={data.jobTitle}
            resumeData={data.resumeData}
            jobTitles={data.jobTitles}
        />,
        <ConversionHowItWorks
            key={2}
            topSkills={data.topSkills}
            jobPositions={data.jobTitlePlural}
        />,
        <ConversionTestimonials key={3} testimonials={data.testimonials} />,
        <PricingSection key={4} />,
        <FAQComponent key={5} />,
    ];

    return (
        <main>
            <ConversionHero
                key={0}
                content={{
                    job_title: data.jobTitle,
                    job_positions: data.jobPositions,
                }}
            />
            {sections.map((section, index) => (
                <AlternatingBackground index={index} key={section.key}>
                    {section}
                </AlternatingBackground>
            ))}
        </main>
    );
}
